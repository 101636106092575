<template>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Barrel Sensory Evaluation</h1>
          <p class="mt-2 text-sm text-gray-700">Select a barrel to evaluate</p>
        </div>
      </div>



      <div class="mt-8 flow-root">
      <div class="relative">
            <!-- Search input -->
    <div class="relative mt-2 rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        v-model="searchTerm"
        class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Search barrel batches..."
        @focus="showDropdown = true"
        @click.stop
      />
    </div>

    <!-- Dropdown Results -->
    <div v-if="showDropdown && filteredBarrelBatches.length > 0" 
         class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
         @click.stop
    >
      <div v-for="batch in filteredBarrelBatches" 
           :key="batch._id"
           @click="selectBarrelBatch(batch)"
           class="relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100">
        <div class="flex items-center">
          <span class="font-normal block truncate">
            Barrel: {{ batch.barrel }} - Batch: {{ batch.batchID }}
          </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Selected Barrel Batch Details -->
      <div v-if="selectedBatch" class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Barrel Details</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div v-if="barrelDetails">
                <p>Source: {{ barrelDetails.Source }}</p>
                <p>Type: {{ barrelDetails.Type }}</p>
                <p>Size: {{ barrelDetails.Size }}</p>
                <p>Origin: {{ barrelDetails.Origin }}</p>
              </div>
            </dd>
          </div>
          <!-- Add more batch details as needed -->
        </dl>
      </div>
    </div>
  </div>

  <div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4">
    <div class="w-full px-4 bg-gray-900">
      <h3 class="text-3xl font-medium leading-6 border-b-2 border-gray-900 py-2 flex items-center text-white">
        Appearance <span class="ml-2 text-gray-400">Apparence</span>
      </h3>
    </div>
  </div>
</div>

  <div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4 pb-6">
    <!-- Clarity Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Clarity
        <span class="block text-sm font-dark">Clarité</span>
      </h3>
    </div>
    
    <!-- Intensity Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Intensity
        <span class="block text-sm font-dark">Intensité</span>
      </h3>
    </div>
    
    <!-- Colour Title -->
    <div class="w-4/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Colour
        <span class="block text-sm font-dark">Couleur</span>
      </h3>
    </div>
  </div>

  <!-- Checkboxes Row -->
  <div class="flex pl-8">
    <!-- Clarity Checkboxes -->
    <div class="w-1/6">
      <div class="space-y-4">
        <!-- Existing clarity checkboxes -->
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="clarity-clear" name="clarity" type="radio" value="clear" v-model="evalDetails.Appearance.clarity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.clarity === 'clear'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="clarity-clear" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Clear</span>
            <span class="text-sm text-gray-500">Clair</span>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="clarity-hazy" name="clarity" type="radio" value="hazy" v-model="evalDetails.Appearance.clarity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.clarity === 'hazy'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="clarity-hazy" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Hazy</span>
            <span class="text-sm text-gray-500">Troublé</span>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="clarity-faulty" name="clarity" type="radio" value="faulty" v-model="evalDetails.Appearance.clarity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.clarity === 'faulty'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="clarity-faulty" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Faulty</span>
            <span class="text-sm text-gray-500">Défectueux</span>
          </label>
        </div>
        <!-- ... other clarity options ... -->
      </div>
    </div>

    <!-- Intensity Checkboxes -->
    <div class="w-1/6">
      <div class="space-y-4">
      <!-- Move your existing intensity checkboxes here -->
      <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="intensity-water" name="intensity" type="radio" value="water" v-model="evalDetails.Appearance.intensity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.intensity === 'water'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="intensity-water" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Water White</span>
            <span class="text-sm text-gray-500">Blanc Eau</span>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="intensity-pale" name="intensity" type="radio" value="pale" v-model="evalDetails.Appearance.intensity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.intensity === 'pale'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="intensity-pale" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Pale</span>
            <span class="text-sm text-gray-500">Pâle</span>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="intensity-medium" name="intensity" type="radio" value="medium" v-model="evalDetails.Appearance.intensity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.intensity === 'medium'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="intensity-medium" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium</span>
            <span class="text-sm text-gray-500">Médium</span>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="intensity-opaque" name="intensity" type="radio" value="opaque" v-model="evalDetails.Appearance.intensity" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
              <span v-if="evalDetails.Appearance.intensity === 'opaque'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="intensity-opaque" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Opaque</span>
            <span class="text-sm text-gray-500">Opaque</span>
          </label>
        </div>
      </div>
        <!-- Move your existing intensity checkboxes here -->
    </div>

    <!-- colour Checkboxes -->
    <div class="w-4/6">
      <!-- Move your existing colour checkboxes here -->
      <div class="grid grid-cols-5 gap-4">
      <!-- Column 1 -->
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.0" name="colour" type="radio" value="0.0" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-white">
              <span v-if="evalDetails.Appearance.colour === '0.0'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.0" class="ml-2 text-xs">
            <div class="font-medium">0.0</div>
            <div class="text-gray-900">Gin Clear</div>
            <div class="text-gray-500">Gin Clair</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.1" name="colour" type="radio" value="0.1" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#fffddc]">
              <span v-if="evalDetails.Appearance.colour === '0.1'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.1" class="ml-2 text-xs">
            <div class="font-medium">0.1</div>
            <div class="text-gray-900">White Wine</div>
            <div class="text-gray-500">Vin Blanc</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.2" name="colour" type="radio" value="0.2" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#feee97]">
              <span v-if="evalDetails.Appearance.colour === '0.2'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.2" class="ml-2 text-xs">
            <div class="font-medium">0.2</div>
            <div class="text-gray-900">Pale Straw</div>
            <div class="text-gray-500">Paille Pâle</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.3" name="colour" type="radio" value="0.3" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#fbea78]">
              <span v-if="evalDetails.Appearance.colour === '0.3'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.3" class="ml-2 text-xs">
            <div class="font-medium">0.3</div>
            <div class="text-gray-900">Pale Gold</div>
            <div class="text-gray-500">Or Pâle</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.4" name="colour" type="radio" value="0.4" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#fae065]">
              <span v-if="evalDetails.Appearance.colour === '0.4'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.4" class="ml-2 text-xs">
            <div class="font-medium">0.4</div>
            <div class="text-gray-900">Corn</div>
            <div class="text-gray-500">Maïs</div>
          </label>
        </div>
      </div>

      <!-- Column 2 -->
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.5" name="colour" type="radio" value="0.5" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f7db54]">
              <span v-if="evalDetails.Appearance.colour === '0.5'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.5" class="ml-2 text-xs">
            <div class="font-medium">0.5</div>
            <div class="text-gray-900">Yellow Gold</div>
            <div class="text-gray-500">Or Jaune</div>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.6" name="colour" type="radio" value="0.6" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f8d849]">
              <span v-if="evalDetails.Appearance.colour === '0.6'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.6" class="ml-2 text-xs">
            <div class="font-medium">0.6</div>
            <div class="text-gray-900">Old Gold</div>
            <div class="text-gray-500">Vieil Or</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.7" name="colour" type="radio" value="0.7" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f7cf49]">
              <span v-if="evalDetails.Appearance.colour === '0.7'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.7" class="ml-2 text-xs">
            <div class="font-medium">0.7</div>
            <div class="text-gray-900">Amber</div>
            <div class="text-gray-500">Ambre</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.8" name="colour" type="radio" value="0.8" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f6ca4d]">
              <span v-if="evalDetails.Appearance.colour === '0.8'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.8" class="ml-2 text-xs">
            <div class="font-medium">0.8</div>
            <div class="text-gray-900">Deep Gold</div>
            <div class="text-gray-500">Or Profond</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-0.9" name="colour" type="radio" value="0.9" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f6c23b]">
              <span v-if="evalDetails.Appearance.colour === '0.9'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-0.9" class="ml-2 text-xs">
            <div class="font-medium">0.9</div>
            <div class="text-gray-900">Light Sherry</div>
            <div class="text-gray-500">Sherry Léger</div>
          </label>
        </div>
      </div>

      <!-- Column 3 -->
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.0" name="colour" type="radio" value="1.0" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#f5be30]">
              <span v-if="evalDetails.Appearance.colour === '1.0'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.0" class="ml-2 text-xs">
            <div class="font-medium">1.0</div>
            <div class="text-gray-900">Deep Copper</div>
            <div class="text-gray-500">Cuivre Profond</div>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.1" name="colour" type="radio" value="1.1" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#eaad07]">
              <span v-if="evalDetails.Appearance.colour === '1.1'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.1" class="ml-2 text-xs">
            <div class="font-medium">1.1</div>
            <div class="text-gray-900">Burnished</div>
            <div class="text-gray-500">Brunie</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.2" name="colour" type="radio" value="1.2" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#e79e14]">
              <span v-if="evalDetails.Appearance.colour === '1.2'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.2" class="ml-2 text-xs">
            <div class="font-medium">1.2</div>
            <div class="text-gray-900">Oloroso Sherry</div>
            <div class="text-gray-500">Sherry Olorosso</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.3" name="colour" type="radio" value="1.3" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#e59502]">
              <span v-if="evalDetails.Appearance.colour === '1.3'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.3" class="ml-2 text-xs">
            <div class="font-medium">1.3</div>
            <div class="text-gray-900">Ripe Muscat</div>
            <div class="text-gray-500">Muscat Mûre</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.4" name="colour" type="radio" value="1.4" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#dc7415]">
              <span v-if="evalDetails.Appearance.colour === '1.4'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.4" class="ml-2 text-xs">
            <div class="font-medium">1.4</div>
            <div class="text-gray-900">Tawny</div>
            <div class="text-gray-500">Fauve</div>
          </label>
        </div>
      </div>

      <!-- Column 4 -->
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.5" name="colour" type="radio" value="1.5" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#e06529]">
              <span v-if="evalDetails.Appearance.colour === '1.5'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.5" class="ml-2 text-xs">
            <div class="font-medium">1.5</div>
            <div class="text-gray-900">Auburn</div>
            <div class="text-gray-500">Auburn</div>
          </label>
        </div>
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.6" name="colour" type="radio" value="1.6" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#cc4f2f]">
              <span v-if="evalDetails.Appearance.colour === '1.6'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.6" class="ml-2 text-xs">
            <div class="font-medium">1.6</div>
            <div class="text-gray-900">Mahogany</div>
            <div class="text-gray-500">Acajou</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.7" name="colour" type="radio" value="1.7" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#af3029]">
              <span v-if="evalDetails.Appearance.colour === '1.7'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.7" class="ml-2 text-xs">
            <div class="font-medium">1.7</div>
            <div class="text-gray-900">Burnt Umber</div>
            <div class="text-gray-500">Ombre brûlée</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.8" name="colour" type="radio" value="1.8" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#a1221b]">
              <span v-if="evalDetails.Appearance.colour === '1.8'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.8" class="ml-2 text-xs">
            <div class="font-medium">1.8</div>
            <div class="text-gray-900">Old Oak</div>
            <div class="text-gray-500">Vieux Chêne</div>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-1.9" name="colour" type="radio" value="1.9" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#722d1d]">
              <span v-if="evalDetails.Appearance.colour === '1.9'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-1.9" class="ml-2 text-xs">
            <div class="font-medium">1.9</div>
            <div class="text-gray-900">Brown Sherry</div>
            <div class="text-gray-500">Sherry Brun</div>
          </label>
        </div>
      </div>

      <!-- Column 5 -->
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="colour-2.0" name="colour" type="radio" value="2.0" v-model="evalDetails.Appearance.colour" class="h-10 w-10 opacity-0 absolute" />
            <div class="h-10 w-10 border border-gray-400 flex items-center justify-center bg-[#421b0a]">
              <span v-if="evalDetails.Appearance.colour === '2.0'" class="text-black text-2xl font-bold">X</span>
            </div>
          </div>
          <label for="colour-2.0" class="ml-2 text-xs">
            <div class="font-medium">2.0</div>
            <div class="text-gray-900">Molasses</div>
            <div class="text-gray-500">Mélasse</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4">
    <div class="w-full px-4 bg-gray-900">
      <h3 class="text-3xl font-medium leading-6 border-b-2 border-gray-900 py-2 flex items-center text-white">
        Nose <span class="ml-2 text-gray-400">Nez</span>
      </h3>
    </div>
  </div>
</div>

<div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4 pb-6">
    <!-- Clarity Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Condition
        <span class="block text-sm font-dark">Condition</span>
      </h3>
    </div>
    
    <!-- Intensity Title -->
    <div class="w-2/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Aroma Intensity
        <span class="block text-sm font-dark">Intensité Aromatique </span>
      </h3>
    </div>
    
    <!-- Spices Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Spices
        <span class="block text-sm font-dark">Épices</span>
      </h3>
    </div>

    <!-- Sweetness Title -->
    <div class="w-2/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Sweetness
        <span class="block text-sm font-dark">Sucre</span>
      </h3>
    </div>

  </div>


    <!-- Checkboxes Row -->
    <div class="flex pl-8">
        <!-- Condition Checkboxes -->
    <div class="w-1/6">
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-clean" name="condition" type="radio" value="clean" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'clean'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-clean" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Clean</span>
            <span class="text-sm text-gray-500">Propre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-unclean" name="condition" type="radio" value="unclean" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'unclean'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-unclean" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Unclean</span>
            <span class="text-sm text-gray-500">Impropre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-faulty" name="condition" type="radio" value="faulty" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'faulty'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-faulty" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Faulty</span>
            <span class="text-sm text-gray-500">Défectueux</span>
          </label>
        </div>
      </div>
    </div>

    <!-- Aroma Intensity Checkboxes -->
    <div class="w-2/6">
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-neutral" name="aromaIntensity" type="radio" value="neutral" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'neutral'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-neutral" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Neutral</span>
            <span class="text-sm text-gray-500">Neutre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-light" name="aromaIntensity" type="radio" value="light" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'light'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-light" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Light</span>
            <span class="text-sm text-gray-500">Léger</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium-minus" name="aromaIntensity" type="radio" value="medium-minus" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium-minus'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium-minus" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium -</span>
            <span class="text-sm text-gray-500">Moyen -</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium" name="aromaIntensity" type="radio" value="medium" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium</span>
            <span class="text-sm text-gray-500">Moyen</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium-plus" name="aromaIntensity" type="radio" value="medium-plus" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium-plus'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium-plus" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium +</span>
            <span class="text-sm text-gray-500">Moyen +</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-pronounced" name="aromaIntensity" type="radio" value="pronounced" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'pronounced'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-pronounced" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Pronounced</span>
            <span class="text-sm text-gray-500">Prononcé</span>
          </label>
        </div>
      </div>
    </div>

  <!-- Spices Checkboxes -->
  <div class="w-1/6">
    <div class="space-y-4">
      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-vanilla" 
            name="spices" 
            type="radio" 
            value="vanilla" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'vanilla'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-vanilla" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Vanilla</span>
          <span class="text-sm text-gray-500">Vanille</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-cinnamon" 
            name="spices" 
            type="radio" 
            value="cinnamon" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'cinnamon'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-cinnamon" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Cinnamon</span>
          <span class="text-sm text-gray-500">Cannelle</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-peppery" 
            name="spices" 
            type="radio" 
            value="peppery" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'peppery'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-peppery" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Peppery</span>
          <span class="text-sm text-gray-500">Poivré</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-coffee" 
            name="spices" 
            type="radio" 
            value="coffee" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'coffee'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-coffee" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Coffee</span>
          <span class="text-sm text-gray-500">Café</span>
        </label>
      </div>
    </div>
  </div>

  <!-- Sweetness Checkboxes -->
  <div class="w-2/6">
    <div class="space-y-4">
      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-honey" 
            name="sweetness" 
            type="radio" 
            value="honey" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'honey'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-honey" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Honey</span>
          <span class="text-sm text-gray-500">Miel</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-maple" 
            name="sweetness" 
            type="radio" 
            value="maple" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'maple'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-maple" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Maple</span>
          <span class="text-sm text-gray-500">Érable</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-molasses" 
            name="sweetness" 
            type="radio" 
            value="molasses" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'molasses'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-molasses" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Molasses</span>
          <span class="text-sm text-gray-500">Mélasse</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-marshmallow" 
            name="sweetness" 
            type="radio" 
            value="marshmallow" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'marshmallow'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-marshmallow" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Marshmallow</span>
          <span class="text-sm text-gray-500">Guimauve</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-sugarcane" 
            name="sweetness" 
            type="radio" 
            value="sugarcane" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'sugarcane'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-sugarcane" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Sugarcane</span>
          <span class="text-sm text-gray-500">Canne à Sucre</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-brownsugar" 
            name="sweetness" 
            type="radio" 
            value="brownsugar" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'brownsugar'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-brownsugar" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Brown Sugar</span>
          <span class="text-sm text-gray-500">Cassonade</span>
        </label>
      </div>
    </div>
  </div>






</div>


  
<div class="relative w-1/2 max-w-2xl mt-4">

  </div>


</div>

<div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4 pb-6">
    <!-- Clarity Title -->
    <div class="w-2/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Fruity
        <span class="block text-sm font-dark">Fruité</span>
      </h3>
    </div>
    
    <!-- Intensity Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Vegetal
        <span class="block text-sm font-dark">Végétal</span>
      </h3>
    </div>
    
    <!-- Spices Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Nutty
        <span class="block text-sm font-dark">Noix</span>
      </h3>
    </div>

    <!-- Sweetness Title -->
    <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Earthy
        <span class="block text-sm font-dark">Terreux</span>
      </h3>
    </div>

     <!-- Sweetness Title -->
     <div class="w-1/6 px-4">
      <h3 class="text-2xl font-medium leading-6 border-b-2 border-gray-900 pb-2">
        Medicinal
        <span class="block text-sm font-dark">Médicinal</span>
      </h3>
    </div>

  </div>


    <!-- Checkboxes Row -->
    <div class="flex pl-8">
        <!-- Condition Checkboxes -->
    <div class="w-1/6">
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-clean" name="condition" type="radio" value="clean" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'clean'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-clean" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Clean</span>
            <span class="text-sm text-gray-500">Propre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-unclean" name="condition" type="radio" value="unclean" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'unclean'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-unclean" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Unclean</span>
            <span class="text-sm text-gray-500">Impropre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="condition-faulty" name="condition" type="radio" value="faulty" v-model="evalDetails.Nose.condition" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.condition === 'faulty'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="condition-faulty" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Faulty</span>
            <span class="text-sm text-gray-500">Défectueux</span>
          </label>
        </div>
      </div>
    </div>

    <!-- Aroma Intensity Checkboxes -->
    <div class="w-2/6">
      <div class="space-y-4">
        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-neutral" name="aromaIntensity" type="radio" value="neutral" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'neutral'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-neutral" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Neutral</span>
            <span class="text-sm text-gray-500">Neutre</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-light" name="aromaIntensity" type="radio" value="light" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'light'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-light" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Light</span>
            <span class="text-sm text-gray-500">Léger</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium-minus" name="aromaIntensity" type="radio" value="medium-minus" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium-minus'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium-minus" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium -</span>
            <span class="text-sm text-gray-500">Moyen -</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium" name="aromaIntensity" type="radio" value="medium" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium</span>
            <span class="text-sm text-gray-500">Moyen</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-medium-plus" name="aromaIntensity" type="radio" value="medium-plus" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'medium-plus'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-medium-plus" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Medium +</span>
            <span class="text-sm text-gray-500">Moyen +</span>
          </label>
        </div>

        <div class="flex items-center">
          <div class="relative flex items-center">
            <input id="aroma-pronounced" name="aromaIntensity" type="radio" value="pronounced" v-model="evalDetails.Nose.aromaIntensity" class="h-10 w-10 opacity-0 absolute" /><div class="h-10 w-10 border border-gray-400 flex items-center justify-center"><span v-if="evalDetails.Nose.aromaIntensity === 'pronounced'" class="text-black text-2xl font-bold">X</span></div>
          </div>
          <label for="aroma-pronounced" class="ml-3 flex flex-col">
            <span class="text-sm font-medium text-gray-900">Pronounced</span>
            <span class="text-sm text-gray-500">Prononcé</span>
          </label>
        </div>
      </div>
    </div>

  <!-- Spices Checkboxes -->
  <div class="w-1/6">
    <div class="space-y-4">
      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-vanilla" 
            name="spices" 
            type="radio" 
            value="vanilla" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'vanilla'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-vanilla" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Vanilla</span>
          <span class="text-sm text-gray-500">Vanille</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-cinnamon" 
            name="spices" 
            type="radio" 
            value="cinnamon" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'cinnamon'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-cinnamon" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Cinnamon</span>
          <span class="text-sm text-gray-500">Cannelle</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-peppery" 
            name="spices" 
            type="radio" 
            value="peppery" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'peppery'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-peppery" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Peppery</span>
          <span class="text-sm text-gray-500">Poivré</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="spices-coffee" 
            name="spices" 
            type="radio" 
            value="coffee" 
            v-model="evalDetails.Nose.spices" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.spices === 'coffee'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="spices-coffee" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Coffee</span>
          <span class="text-sm text-gray-500">Café</span>
        </label>
      </div>
    </div>
  </div>

  <!-- Sweetness Checkboxes -->
  <div class="w-2/6">
    <div class="space-y-4">
      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-honey" 
            name="sweetness" 
            type="radio" 
            value="honey" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'honey'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-honey" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Honey</span>
          <span class="text-sm text-gray-500">Miel</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-maple" 
            name="sweetness" 
            type="radio" 
            value="maple" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'maple'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-maple" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Maple</span>
          <span class="text-sm text-gray-500">Érable</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-molasses" 
            name="sweetness" 
            type="radio" 
            value="molasses" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'molasses'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-molasses" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Molasses</span>
          <span class="text-sm text-gray-500">Mélasse</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-marshmallow" 
            name="sweetness" 
            type="radio" 
            value="marshmallow" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'marshmallow'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-marshmallow" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Marshmallow</span>
          <span class="text-sm text-gray-500">Guimauve</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-sugarcane" 
            name="sweetness" 
            type="radio" 
            value="sugarcane" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'sugarcane'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-sugarcane" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Sugarcane</span>
          <span class="text-sm text-gray-500">Canne à Sucre</span>
        </label>
      </div>

      <div class="flex items-center">
        <div class="relative flex items-center">
          <input 
            id="sweetness-brownsugar" 
            name="sweetness" 
            type="radio" 
            value="brownsugar" 
            v-model="evalDetails.Nose.sweetness" 
            class="h-10 w-10 opacity-0 absolute" 
          />
          <div class="h-10 w-10 border border-gray-400 flex items-center justify-center">
            <span v-if="evalDetails.Nose.sweetness === 'brownsugar'" class="text-black text-2xl font-bold">X</span>
          </div>
        </div>
        <label for="sweetness-brownsugar" class="ml-3 flex flex-col">
          <span class="text-sm font-medium text-gray-900">Brown Sugar</span>
          <span class="text-sm text-gray-500">Cassonade</span>
        </label>
      </div>
    </div>
  </div>

</div>


  
<div class="relative w-1/2 max-w-2xl mt-4">

  </div>


</div>


<div class="mt-4 border-t border-gray-100">
  <!-- Titles Row -->
  <div class="flex justify-between px-4">
    <div class="w-full px-4">
      <h3 class="text-3xl font-medium leading-6 border-b-2 border-gray-900 pb-2 flex items-center">
        Flavour Wheel <span class="ml-2 text-gray-400">Roue des arômes</span>
      </h3>
    </div>
  </div>
</div>

  <!-- ///////OLD CODE /////// -->

  <div v-if="evalDetails" class="mt-4 border-t border-gray-100">
    <dl class="divide-y divide-gray-100">
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">

  <div class="space-y-2">  <!-- Added container with vertical spacing -->
    <div v-if="!selectedBatch" class="mt-8 bg-volcano-red">
  <p class="text-sm font-medium leading-6 px-2 pt-1 text-white">
    Please select a barrel batch to view evaluation details
  </p>
  </div>
    <dt class="text-sm font-medium leading-6 px-2 pt-1 bg-gray-900 text-white">
      Evaluation Created on {{ formatDate(evalDetails.createdAt) }}
    </dt>
    <dt class="text-sm font-medium leading-6 px-2 pt-1 bg-gray-500 text-white">
      Last Modified on {{ formatDate(evalDetails.lastModifiedAt) }}
    </dt>
  </div>
</div>
    </dl>
  </div>
</template>

  
  <script setup>
import { onMounted, ref, computed, watch } from 'vue';
import axios from 'axios';
import { differenceInYears, differenceInMonths, differenceInDays, parseISO, format, isValid } from 'date-fns';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { useRoute, useRouter } from 'vue-router';
import FlavourWheel from '@/components/FlavourWheel.vue';


const route = useRoute();
const router = useRouter(); 

const navigateToBatch = (id) => {
  router.push(`/barrelBatch/${id}`);
};

const searchTerm = ref('');
const barrelBatches = ref([]);
const currentSortColumn = ref(null);
const currentSortDirection = ref(null);

const showDropdown = ref(false);
const selectedBatch = ref(null);
const barrelDetails = ref(null);
const evalDetails = ref({
  Appearance: {
    clarity: '',
    intensity: '',
    colour: ''
  },
  Nose: {
    condition: '', 
    aromaIntensity: '',
    spices:'',
    sweetness: ''
  },
  FlavourWheel: {
    spicy: 0,
    woody: 0,
    nutty: 0,
    leathery: 0,
    rubbery: 0,
    fruity: 0,
    sweet: 0,
    buttery: 0,
    oily: 0,
    hot: 0
  }
});


const selectBarrelBatch = async (batch) => {
  selectedBatch.value = batch;
  searchTerm.value = `Barrel: ${batch.barrel} - Batch: ${batch.batchID}`;
  showDropdown.value = false;
  
  try {
    // Use the barrel field instead of barrel_Id
    const response = await axios.get(`/api/barrel/${batch.barrel}`);
    barrelDetails.value = response.data;
  } catch (error) {
    console.error('Error fetching barrel details:', error);
  }
};

const filteredBarrelBatches = computed(() => {
  if (!searchTerm.value) {
    return barrelBatches.value;
  }
  return barrelBatches.value.filter((batch) => {
    // Add null checks and default values
    const barrelStr = (batch.barrel || '').toString().toLowerCase();
    const batchIDStr = (batch.batchID || '').toString().toLowerCase();
    const searchStr = searchTerm.value.toLowerCase();
    
    return barrelStr.includes(searchStr) || 
           batchIDStr.includes(searchStr);
  });
});

// Add click outside handler to close dropdown
onMounted(() => {
  document.addEventListener('click', (e) => {
    // Only close if clicking outside both the input and dropdown
    const searchContainer = document.querySelector('.relative');
    if (searchContainer && !searchContainer.contains(e.target)) {
      showDropdown.value = false;
    }
  });
});


// In your setup function
const handleFlavourWheelChange = (newData) => {
  flavourWheelData.value = { ...newData };
};

onMounted(async () => {
  try {
    const response = await axios.get('/api/barrelBatch/list');
    if (response.data) {
      barrelBatches.value = response.data;
    } else {
      barrelBatches.value = [];
    }
  } catch (error) {
    console.error('Error fetching barrel batches:', error);
    barrelBatches.value = [];
  }
});

const computeAge = (fillingDate) => {
  const now = new Date();
  const date = parseISO(fillingDate);
  let years = differenceInYears(now, date);
  let months = differenceInMonths(now, date) % 12;
  let days = differenceInDays(now, date) % 30; // Approximation, as months have different number of days

  return `${years} years, ${months} months, ${days} days`;
};

const computeAgeInDays = (fillingDate) => {
  const now = new Date();
  const date = parseISO(fillingDate);
  return differenceInDays(now, date);
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A'; // Return placeholder if no date
  
  const date = parseISO(dateString);
  if (!isValid(date)) {
    console.warn('Invalid date:', dateString);
    return 'Invalid Date';
  }
  return format(date, 'dd-MM-yyyy HH:mm');
};

const sortData = (column) => {
  if (currentSortColumn.value === column) {
    currentSortDirection.value = currentSortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSortColumn.value = column;
    currentSortDirection.value = 'asc';
  }

  if (column === 'age') {
    barrelBatches.value.sort((a, b) => {
      const ageA = computeAgeInDays(a.fillingDate);
      const ageB = computeAgeInDays(b.fillingDate);
      return currentSortDirection.value === 'asc' ? ageA - ageB : ageB - ageA;
    });
  } else {
    // Handle other columns as before
    barrelBatches.value.sort((a, b) => {
      if (a[column] < b[column]) return currentSortDirection.value === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return currentSortDirection.value === 'asc' ? 1 : -1;
      return 0;
    });
  }
};



// Add watch to handle changes
watch(evalDetails, async (newValue) => {
  if (route.params.id) {
    try {
      await axios.put(`/api/barrelEval/${route.params.id}`, newValue);
    } catch (error) {
      console.error('Error updating evaluation:', error);
    }
  }
}, { deep: true });

const fetchEvalDetails = async () => {
  try {
    const response = await axios.get(`/api/barrelEval/${route.params.id}`);
    if (response.data) {
      evalDetails.value = {
        Appearance: {
          clarity: response.data.Appearance?.clarity || '',
          intensity: response.data.Appearance?.intensity || '', 
          colour: response.data.Appearance?.colour || '' 
        },
        Nose: {
          condition: response.data.Nose?.condition || '',
          aromaIntensity: response.data.Nose?.aromaIntensity || ''
        },
        FlavourWheel: {
          spicy: Number(response.data.FlavourWheel?.spicy || 0),
          woody: Number(response.data.FlavourWheel?.woody || 0),
          nutty: Number(response.data.FlavourWheel?.nutty || 0),
          leathery: Number(response.data.FlavourWheel?.leathery || 0),
          rubbery: Number(response.data.FlavourWheel?.rubbery || 0),
          fruity: Number(response.data.FlavourWheel?.fruity || 0),
          sweet: Number(response.data.FlavourWheel?.sweet || 0),
          buttery: Number(response.data.FlavourWheel?.buttery || 0),
          oily: Number(response.data.FlavourWheel?.oily || 0),
          hot: Number(response.data.FlavourWheel?.hot || 0)
        },
        createdAt: response.data.createdAt || null,
        lastModifiedAt: response.data.lastModifiedAt || null
      };
      console.log('Fetched evaluation details:', evalDetails.value);
    }
  } catch (error) {
    console.error('Error fetching evaluation details:', error);
  }
};

// Call fetchEvalDetails when component mounts
onMounted(() => {
  fetchEvalDetails();
});



  </script>