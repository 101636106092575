// client/src/main.js
import { createApp, ref } from 'vue';
import { createPinia } from 'pinia'; 
import ElementPlus from 'element-plus';

import piniaPersistedState from 'pinia-plugin-persistedstate';
import { auth, db } from './firebase';
import { setPersistence, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import App from './App.vue';
import router from './router/router';
import './tailwind.css';
import './axiosConfig';
import { toast } from 'vue3-toastify';
import { useDutyFreeStore } from './stores/DutyFree';
import 'element-plus/dist/index.css'
import './assets/styles/global.css'; // Import global styles

import { plugin as formKitPlugin } from '@formkit/vue';
import formKitConfig from '../formkit.config.mjs';

let userRole = ref(null); // Vue reactive variable to store the user's role

onAuthStateChanged(auth, async (user) => {
  if (user) {
    // User is signed in, get their role from Firestore
    const docRef = doc(db, "users", user.uid); // replace "users" with the name of your collection
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      userRole.value = docSnap.data().role; // replace "role" with the name of your role field
    } else {
      console.log("No such document!");
    }

    //console.log('User is logged in:', user);
  } else {
    // User is signed out
    userRole.value = null;
    //console.log('User is logged out');
  }
  
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiredRole = to.meta.requiredRole;

    if (requiresAuth && !auth.currentUser) {
      next('/login');
    } else if (requiredRole && !requiredRole.includes(userRole.value)) {
      next('/not-authorized');
    } else {
      next();
    }
  });
});

// Create a Pinia instance
const pinia = createPinia();

// Use the persisted state plugin
pinia.use(piniaPersistedState);

const app = createApp(App);

// Use the toast notification plugin
app.use(pinia);
app.use(router);
app.use(ElementPlus);

// Register FormKit with its default configuration
app.use(formKitPlugin, formKitConfig); // Pass the config object directly

app.mount('#app')

// })
// .catch((error) => {
//   console.error('Failed to set auth persistence:', error);
// });